import React, { useEffect, useState } from "react";
import classes from "./AddRestaurants.module.css";
import Headline2 from "../../../../subComponents/pageUI/Headline2";
import { Box, Divider, Grid, Typography, InputAdornment } from "@mui/material";
import CustomTextField from "../../../../subComponents/formUI/CustomTextField";
import CustomSelect from "../../../../subComponents/formUI/CustomSelect";
import CustomButton from "../../../../subComponents/formUI/CustomButton";
import { usePasswordValidations } from "../../../../CustomHooks/usePasswordValidations";
import CustomRadio from "../../../../subComponents/formUI/CustomRadio";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import { toast } from "react-toastify";
import LargeText from "../../../../subComponents/formUI/LargeText";
import { getCampusByCity } from "../../../../services/restaurant";
import NewFileUploadComponent from "../../../../subComponents/formUI/NewFileUploadComponent";
import UpliadImageFieldWithoutFormik from "../../../../subComponents/formUI/UpliadImageFieldWithoutFormik";
import imageRounded from "../../../../assets/Images/imageRounded.png";
import axios from "axios";
import { useSelector } from "react-redux";

const OutletInfo = ({
  campusList,
  cityList,
  onSubmit,
  onSet,
  bankList,
  sx,
  previousPage,
  getDocuments,
  data
}) => {
  const [img, setImg] = useState(null);
  const [file, setFile] = useState(null);
  const [cityCampus, setCityCampus] = useState(null);
  const [restoImg, setRestoImg] = useState(null);
  const [file1, setFile1] = useState(null);
  const [gstValue, setGstValue] = useState(null)

  const [restaurantDetailsInitialState] = useState({
    outletName: data?.outletName || "",
    email: "",
    mobile: "",
    GSTIN: data?.GSTIN || "",
    address: "",
    password: "",
    campusId: data?.campusId?.campusId || "",
    // FSSAI_License: "",
    accountNumber: "",
    BankName: data?.bankDetailsId?.bankId || "",
    IFSCCode: "",
    cityId: data?.cityId || "",
    FSSAI_number: data?.FSSAI_number || null,
    bankId: data?.bankDetailsId?.bankId || '',
    bankDetailsId: data?.bankDetailsId?.bankDetailsId || '',
    convenienceFee: data?.convenienceFee || null,
    commissionFee: data?.commissionFee || null,
    bankLabel: data?.bankLabel || null,
    isGSTShow: data?.isGSTShow || true,
    hasMess:data?.hasMess || false,
  });
  const [restaurantFormData, setRestaurantFormData] = useState({ ...restaurantDetailsInitialState })

  const {
    outletName,
    GSTIN,
    password,
    campusId,
    cityId,
    address,
    accountNumber,
    BankName,
    IFSCCode,
    FSSAI_number,
    bankId,
    commissionFee,
    convenienceFee,
    bankLabel,
    hasMess
  } = restaurantFormData;

  // console.log("restaurantFormData ",restaurantFormData)

  const gstRegex = ((
    gstValue?.trim() !== "" &&
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(gstValue)
  ))

  useEffect(() => {
  }, [restaurantDetailsInitialState])

  useEffect(() => {
    setRestaurantFormData({
      outletName: data?.outletName || "",
      email: "",
      mobile: "",
      GSTIN: data?.GSTIN || "",
      address: data?.address || "",
      password: "",
      campusId: data?.campusId?.campusId || "",
      accountNumber: data?.bankDetailsId?.accountNumber || "",
      BankName: data?.bankDetailsId?.bankId || "",
      IFSCCode: data?.bankDetailsId?.IFSCCode || "",
      cityId: data?.cityId || "",
      FSSAI_number: data?.FSSAI_number || '',
      bankId: data?.bankDetailsId?.bankId || '',
      bankDetailsId: data?.bankDetailsId?.bankDetailsId || bankId,
      convenienceFee: data?.convenienceFee !== null ? data?.convenienceFee?.toString() : '',
      commissionFee: data?.commissionFee !== null ? data?.commissionFee?.toString() : '',
      bankLabel: data?.bankLabel || null,
      isGSTShow: data?.isGSTShow || true,
      hasMess: data?.hasMess || false
    })
  }, [data])

  const onDrop = useCallback((acceptedFiles) => {
    // Filter acceptedFiles to keep only image files
    const imageFiles = acceptedFiles.filter((file) =>
      file.type.startsWith("image/")
    );
    let file = imageFiles[0];
    if (imageFiles.length === 0) {
      // No image files found, show a warning message
      toast.warning("Please select image files only.");
      return;
    }

    // Do something with the image files
    // For now, we'll just log the first image file.
    setFile(imageFiles[0]);

    axios.post(
      process.env.REACT_APP_BACKEND_BASE_URL + "outlet/upsertFssaiLicensePhoto",
      convertObjectToFormData({
        file: file,
        outletId: outletId,
      }),
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    ).then((res) => console.log(res)).catch((error) => console.log(error));
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result.split(",")[1];
      const imageUrl = `data:${file.type};base64,${base64String}`;
      setImg(imageUrl);
    };

    reader.readAsDataURL(file);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const [isConvenienceFeeTouch, setIsConvenienceFeeTouched] = useState(false);
  const [isCommisionFeeTouch, setIsCommisionFeeTouched] = useState(false);
  const [isRestaurantNameTouch, setIsRestaurantNameTouch] = useState(false);
  const [isBankNameTouch, setIsBankNameTouched] = useState(false);

  const outletId = useSelector((state) => state.auth.user.adminId);
  const [selectedValue, setSelectedValue] = useState("Yes");

  function convertObjectToFormData(data) {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];
      formData.append(key, value);
    });
    return formData;
  }

  useEffect(() => {
    if (data?.isGSTShow === false)
      setSelectedValue('No')
    if (data?.GSTIN) {
      setGstValue(data?.GSTIN)
    }
    getCampusByCity(cityId)
      .then((res) => {
        let tempArr = [];
        {
          res.data.data.length === 0 ? tempArr.push({ name: 'No Campus Available ', value: 1 }) :
            res.data.data.map((e) => {
              tempArr.push({
                name: e.campusName,
                value: e.campusId,
              })
              return tempArr
            });
        }
        setCityCampus(tempArr)
      })
      .catch((error) => console.log(error));
  }, [cityId, data]);

  const onChange = (e, name) => {
    if (e.target.name === 'BankName') {
      setRestaurantFormData({
        ...restaurantFormData,
        [e.target.name]: name,
        bankId: e.target.value
      });
    }
    else {
      setRestaurantFormData({
        ...restaurantFormData,
        [e.target.name]: e.target.value,
        
      });
    }
  };

  const isFormValid = () => {

    if (FSSAI_number?.length > 0 && !(/^[0-9]{14}$/.test(FSSAI_number))) {
      return false;
    }

    if (GSTIN?.length > 0 && !gstRegex) {
      return false;
    }
    if(selectedValue == "Yes" && GSTIN?.length <= 0)
    {
      return false;
    }
    // if (!commissionFee || isNaN(commissionFee) || Number(commissionFee) < 0.1) {
    if (!commissionFee || isNaN(commissionFee) || Number(commissionFee) < 0) {
      return false;
    }

    if (data) {
      let flag = (outletName?.trim() !== "" && outletName?.length >= 3 && outletName?.length <= 30 &&
        address?.trim() !== "" && campusId && cityId && convenienceFee?.toString()?.length > 0 &&
        bankLabel?.toString()?.length > 0 && commissionFee?.toString()?.length > 0 && GSTIN?.length >0 && gstRegex)
      return flag
    }
    else {
      let flag = (outletName?.trim() !== "" && outletName?.length >= 3 && outletName?.length <= 30 &&
        address?.trim() !== "" && password && campusId && cityId && convenienceFee?.length > 0 &&
        bankLabel?.length > 0 && commissionFee?.length > 0  )
      return flag
    }
  };

  const optionList = [{ name: "Yes" }, { name: "No" }];

  const handleCategoryChange = (e) => {
    setSelectedValue(e.target.value);
    if (e.target.value === "No")
      setRestaurantFormData((p) => ({ ...p, isGSTShow: false }));
    else
      setRestaurantFormData((p) => ({ ...p, isGSTShow: true }));
  };
  const [validLength, hasNumber, upperCase, lowerCase, specialChar] =
    usePasswordValidations({
      firstPassword: password,
    });

  const combineDatas = { ...restaurantFormData, FSSAI_License: file || data?.FSSAI_License };
  const handleSubmit = () => {
    if (selectedValue == "Yes") {
      if (!GSTIN) {
        return toast.error("Add GSTIN");
      } else {
        onSubmit?.(combineDatas);
        onSet(combineDatas);
        getDocuments([file, file1, restoImg])
      }
    } else {
    onSubmit?.(combineDatas);
    onSet(combineDatas);
    getDocuments([file, file1, restoImg])
    }
  };

  // const stack2 = (
  //   <div className={classes.container}>
  //     {FSSAI_License ? (
  //       FSSAI_License.name
  //     ) : (
  //       <>
  //         <div className={classes.addIconMain}>
  //           <div className={classes.box1}>
  //             <AddIcon />
  //           </div>
  //         </div>
  //         <div className={classes.box2}>
  //           <Typography
  //             sx={{ fontSize: 14, textAlign: "center" }}
  //             color="text.secondary"
  //             gutterBottom
  //           >
  //             Drop your FSSAI Licence here, or{" "}
  //             <Typography
  //               sx={{ display: "inline", color: "#4F46E5", cursor: "pointer" }}
  //             >
  //               click here to browse
  //             </Typography>
  //           </Typography>
  //         </div>
  //       </>
  //     )}
  //   </div>
  // );
  // console.log(cityList)

  // console.log(!(/^[0-9]+(\.[0-9]+)?$/.test(commissionFee)), commissionFee?.length <= 0, (Number(commissionFee) === 0));

  const handleBack = () => {
    previousPage();
  };
  return (
    <div style={sx}>
      <div className={classes.formFieldWrapper}>
        <div className={classes.formFieldLayout}>
          <div>
            <Headline2
              children={"Restaurant Basic Details:"}
              sx={{
                fontSize: "1.125rem",
                fontWeight: "0",
                paddingBottom: "8px",
                color: "#94A3B8",
              }}
            />
            <Divider />
          </div>
          <Grid container spacing={"16px"} mt={"18px"}>
            <Grid item xs={12} pb={"2px"}>
              <CustomTextField
                required
                label="Restaurant Name"
                name="outletName"
                value={outletName}
                hasError={isRestaurantNameTouch && !(outletName?.trim() !== "" && (/^[a-zA-Z0-9!@#$%^&*)(](\s?[a-zA-Z0-9!@#$%^&*)(]){2,29}$/.test(outletName)))}
                // ^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+ [a-zA-Z](\s?[a-zA-Z]){2,29}
                errorMassage={"This value must be between 3 to 30 characters"}
                onChange={onChange}
                placeholder="Enter your Reastaurant name"
                onBlur={() => setIsRestaurantNameTouch(true)}
              />
            </Grid>
            <Grid item xs={12} md={12}>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <input style={{marginRight:"0.5rem"}} onChange={onChange} type="radio" name="hasMess" value="true" checked={restaurantFormData?.hasMess == true} />
                  <label>Mess</label>
                </Box>

                <Box style={{ display: "flex", alignItems: "center",marginTop:"0.3rem" }}>
                  <input style={{marginRight:"0.5rem"}} onChange={onChange} type="radio" name="hasMess" value="false" checked={restaurantFormData?.hasMess == false}/>
                  <label>Outlet</label>
                </Box>
              </Grid>
            <Grid item xs={6}>
              <CustomSelect
                fieldName={"city"}
                fieldValue={"cityId"}
                required
                name="cityId"
                value={restaurantFormData?.cityId}
                key={restaurantFormData?.cityId}
                optionList={cityList}
                label="Select City"
                onChange={onChange}
                placeholder="Select"
              />
            </Grid>
            <Grid item xs={6}>
              <CustomSelect
                fieldName={"campusName"}
                fieldValue={"campusId"}
                required
                name="campusId"
                value={restaurantFormData?.campusId}
                key={restaurantFormData?.campusId}
                optionList={cityCampus}
                label="Select Campus"
                onChange={onChange}
                placeholder="Select"
                disabled={!cityId}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                required
                label="Restaurant Address"
                name="address"
                onChange={onChange}
                value={address}
                onInput={(e) => {
                  if (e?.target?.value) {
                    e.target.value = e?.target?.value.slice(0, 50);
                  }
                }}
                placeholder="Enter your Reastaurant address"
                multiline
                maxRows={4}
                height="100px"
                inputBaseSx={{ alignItems: "flex-start" }}
              />
              {/* alignItems : 'flex-start', */}
            </Grid>

            <Grid></Grid>
          </Grid>

          {!Boolean(data) &&
            <>
              <div>
                <Headline2
                  children={"Password"}
                  sx={{
                    fontSize: "1.125rem",
                    fontWeight: "0",
                    paddingBottom: "8px",
                    color: "#94A3B8",
                    marginTop: "36px",
                  }}
                />
                <Divider />
              </div>
              <Grid container mt={"20px"}>
                <Grid item xs={8}>
                  <CustomTextField
                    required
                    label="Password"
                    name="password"
                    value={password}
                    disabled={data}
                    onChange={onChange}
                    placeholder="Enter Password"
                    hasError={
                      validLength ||
                      upperCase ||
                      lowerCase ||
                      specialChar ||
                      hasNumber
                    }
                    errorMassage={
                      !validLength
                        ? "Please include at least 8 character"
                        : !upperCase
                          ? "Please include at least 1 upper case"
                          : !lowerCase
                            ? "Please include at least 1 lower case"
                            : !specialChar
                              ? "Please include at least 1 special character"
                              : !hasNumber
                                ? "Please include at least 1 number"
                                : ""
                    }
                  />
                </Grid>
              </Grid>
            </>
          }
          <div>
            <Headline2
              children={"Tax"}
              sx={{
                fontSize: "1.125rem",
                fontWeight: "0",
                paddingBottom: "8px",
                color: "#94A3B8",
                marginTop: "36px",
              }}
            />
            <Divider />
          </div>
          <Grid container mt={"20px"}>
            <Grid item xs={12}>
              <Typography>Regular GSTIN</Typography>
              <CustomRadio
                value={selectedValue}
                optionList={optionList}
                handleCategoryChange={handleCategoryChange}
                gap={10}
                name="isGSTShow"
                // radioContainerStyle={{display:"flex",width:"174.75px",padding:"12px 16px",alignItems:"flex-start",gap:"10px"}}
                sx={{
                  display: "flex",
                  width: "174.75px",
                  padding: "12px 16px",
                  alignItems: "flex-start",
                  // alignItems: "center",
                  gap: "10px",
                  flexShrink: "0",
                  borderRadius: "12px",
                  border: "1px solid var(--gray-200, #E2E8F0)",
                  marginBottom: "20px",
                  // marginLeft:"5px",
                }}
                radioContainerStyle={{
                  gap: "10px",
                  marginTop: "20px",
                  marginLeft: "15px",
                }}
              />
            </Grid>


            <Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"} gap={"1rem"}>
              {/* {selectedValue === "Yes" && ( */}
              <>
                <Box flex={"0 0 calc(50% - 1rem)"}>
                {selectedValue === "Yes" ? 
                  <CustomTextField
                    required
                    label="Restaurant GSTIN Number"
                    name="GSTIN"
                    // value={selectedValue === "No" ? "" : (GSTIN)}
                    value={GSTIN}
                    onChange={(e) => {
                      setGstValue(e.target.value)
                      onChange(e)
                    }}
                    hasError={ GSTIN?.length === 0 ? false : !gstRegex}
                    // hasError={GSTIN?.length === 0 ? false : !gstRegex}
                    errorMassage={"Please enter valid GST number"}
                    placeholder="Enter your GST Number"
                    // disabled={selectedValue === "Yes" ? false : true}
                    // onBlur={() => setGstTouched(true)}
                    onInput={(e) => {
                      if (e?.target?.value) {
                        e.target.value = e?.target?.value.replace(/[^a-z0-9]/gi, '').slice(0, 15);
                      }
                    }}
                  /> :
                  <CustomTextField
                    label="Restaurant GSTIN Number"
                    name="GSTIN"
                    // value={selectedValue === "No" ? "" : (GSTIN)}
                    value={GSTIN}
                    onChange={(e) => {
                      setGstValue(e.target.value)
                      onChange(e)
                    }}
                    // hasError={ !gstRegex}
                    hasError={GSTIN?.length === 0 ? false : !gstRegex}
                    errorMassage={"Please enter valid GST number"}
                    placeholder="Enter your GST Number"
                    // disabled={selectedValue === "Yes" ? false : true}
                    // onBlur={() => setGstTouched(true)}
                    onInput={(e) => {
                      if (e?.target?.value) {
                        e.target.value = e?.target?.value.replace(/[^a-z0-9]/gi, '').slice(0, 15);
                      }
                    }}
                  /> }
                </Box>
              </>
              {/* )} */}
              <Box flex={"0 0 calc(50% - 1rem)"}>
                <CustomTextField
                  name="FSSAI_number"
                  value={FSSAI_number}
                  type={'number'}
                  onChange={onChange}
                  hasError={FSSAI_number?.length === 0 ? false : (!(/^[0-9]{14}$/.test(FSSAI_number)) || FSSAI_number?.length < 14)}
                  onInput={(e) => {
                    if (e?.target?.value) {
                      e.target.value = e?.target?.value.replace(/[^0-9]/gi, '').slice(0, 14);;
                    }
                  }}
                  errorMassage={"Please enter a valid FSSAI number"}
                  label="FSSAI Number"
                  placeholder="Enter your fssai number"
                />
              </Box>
              <Box flex={"0 0 calc(50% - 1rem)"}>
                <CustomTextField
                  required
                  label="Convenience Fee"
                  name="convenienceFee"
                  value={convenienceFee}
                  type={'number'}
                  onChange={onChange}
                  onBlur={(e) => setIsConvenienceFeeTouched(true)}
                  hasError={!(/^[0-9]+(\.[0-9]+)?$/.test(convenienceFee)) && isConvenienceFeeTouch && convenienceFee?.length <= 0}
                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                  onInput={(e) => {
                    if (Number(e?.target?.value) > 100) {
                      e.target.value = e.target.defaultValue;
                    } else {
                      let _value = e?.target?.value?.replace(/(\.\d{2})\d+$/, '$1');
                      _value = e?.target?.value?.replace(/(\.\d{2})\..*/, '$1');
                      if (isNaN(_value)) {
                        _value = "";
                      } else {
                        if (_value.split('.')[1]?.length > 2) {
                          _value = _value.split('.')[0] + "." + _value.split('.')[1].slice(0, 2)
                        }
                      }
                      e.target.value = _value;
                    }
                  }}
                  errorMassage={"Please enter Convenience Fee between 0 to 100"}
                  placeholder="Enter Convenience Fee"
                />
              </Box>

              <Box flex={"0 0 calc(50% - 1rem)"}>
                <CustomTextField
                  required
                  label="commission Fee"
                  name="commissionFee"
                  value={commissionFee}
                  type={'number'}
                  onChange={onChange}
                  onBlur={(e) => setIsCommisionFeeTouched(true)}
                  // hasError={isCommisionFeeTouch && (!(/^[0-9]+(\.[0-9]+)?$/.test(commissionFee)) || commissionFee?.length < 0 || (Number(commissionFee) < 0.1))}
                  hasError={isCommisionFeeTouch && (!(/^[0-9]+(\.[0-9]+)?$/.test(commissionFee)) || commissionFee?.length < 0 || (Number(commissionFee) < 0))}
                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                  onInput={(e) => {
                    if (Number(e?.target?.value) > 100) {
                      e.target.value = e.target.defaultValue;
                    } else {
                      let _value = e?.target?.value?.replace(/(\.\d{2})\d+$/, '$1');
                      _value = e?.target?.value?.replace(/(\.\d{2})\..*/, '$1');
                      if (isNaN(_value)) {
                        _value = "";
                      } else {
                        if (_value.split('.')[1]?.length > 2) {
                          _value = _value.split('.')[0] + "." + _value.split('.')[1].slice(0, 2)
                        }
                      }
                      e.target.value = _value;
                    }
                  }}
                  errorMassage={"Please enter commission Fee between 0 to 100"}
                  placeholder="Enter commission Fee"
                />
              </Box>

              <Box flex={"0 0 calc(50% - 1rem)"}>
                <CustomTextField
                  required
                  label="Bank Label"
                  name="bankLabel"
                  value={bankLabel}
                  onChange={onChange}
                  hasError={!(/^[A-Za-z0-9]{3,30}$/.test(bankLabel)) && isBankNameTouch && bankLabel?.length <= 0}
                  // onInput={(e) => {
                  //   if (e?.target?.value > 100) {
                  //     e.target.value = (e?.target?.value)?.slice(0, 2);
                  //   }
                  //   else {
                  //     e.target.value = e?.target?.value.replace(/[^0-9]/gi, '').slice(0, 3);
                  //   }
                  // }}
                  onBlur={(e) => setIsBankNameTouched(true)}
                  errorMassage={"Please enter a valid Label"}
                  placeholder="Enter Bank Label"
                />
              </Box>
            </Box>


          </Grid>
          <div>
            <Headline2
              children={"Restaurant Documents"}
              sx={{
                fontSize: "1.125rem",
                fontWeight: "0",
                paddingBottom: "8px",
                color: "#94A3B8",
                marginTop: "36px",
              }}
            />
            <Divider />
          </div>
          <Grid container mt={"20px"}>
            {/* <Grid item xs={12}>
              <div style={{ padding: "4px" }}>
                Upload FSSAI Licence ( Optional)
              </div>
              <div className={classes.filePublish}>
                <FileUploader
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                  children={stack2}
                />
              </div>
            </Grid> */}
            <Grid item xs={6}>
              <LargeText
                color="black"
                fontWeight={500}
                sx={{
                  mb: 1,
                }}
              >
                Restaurant Logo
              </LargeText>
              <UpliadImageFieldWithoutFormik
                sx={{
                  borderRadius: "50%",
                  objectFit: "cover",
                  height: "150px",
                  width: "150px",
                }}
                defaultImg={imageRounded}
                defaultValue={restoImg || data?.logo}
                name="resto_img"
                // onChange={(file) => {
                //   axios.post(
                //     process.env.REACT_APP_BACKEND_BASE_URL +
                //       "outlet/upsertLogoImage",
                //     convertObjectToFormData({
                //       file: file,
                //       outletId: outletId,
                //     }),
                //     {
                //       headers: {
                //         "Content-Type": "multipart/form-data",
                //       },
                //     }
                //   );
                // }}
                onChange={(_file) => {
                  console.log(_file);
                  setRestoImg(_file)
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <NewFileUploadComponent
                setFile={setFile1}
                label={"Upload Restaurant Header"}
                defaultImg={file1 || data?.headerImage}
                title={"Upload Restaurant Header"}
                text={"Upload your Restaurant picture"}
              // url={"outlet/upsertHeaderImage"}
              />
            </Grid>
            <Grid item xs={12}>
              {/* <LargeText
                color="black"
                fontWeight={500}
                sx={{
                  mb: 1,
                }}
              >
                Upload FSSAI License (optional)
              </LargeText>
              <Box
                sx={{
                  cursor: "pointer",
                  padding: "32px 16px",
                  border: "1px dashed var(--gray-500, #64748B)",
                  borderRadius: "8px",
                  background: isDragActive ? `whitesmoke` : "transparent",
                  transform: isDragActive && "scale(0.95)",
                  transition: "0.4s",
                  position: "relative",
                }}
              >
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Box
                    sx={{
                      position: "absolute",
                      transform: "scale(0.8)",
                      right: 0,
                      top: 10,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {img && (
                      <CustomDialog
                        buttonComp={(props) => (
                          <CustomButton
                            onClick={(e) => {
                              e.stopPropagation();
                              props.onClick();
                            }}
                          >
                            View File
                          </CustomButton>
                        )}
                      >
                        {" "}
                        <PageHeader secondary title={"Upload FSSAI License"} />
                        <Box
                          sx={{
                            width: 550,
                          }}
                        >
                          <img
                            src={img}
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </Box>
                      </CustomDialog>
                    )}
                  </Box>
                  <Stack
                    justifyContent={"center"}
                    gap={"4px"}
                    alignItems={"center"}
                  >
                    <img
                      src={AddPng}
                      style={{
                        width: 48,
                        height: 48,
                        marginBottom: "14px",
                      }}
                    />
                    <SmallText>
                      {file?.name || "Upload Excel, Csv, max size 5MB each."}
                    </SmallText>
                    <StackRow>
                      <Text color="#0F172A" bold>
                        Drop your file here, or
                      </Text>
                      <Text
                        color="#4F46E5"
                        bold
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        click to browse
                      </Text>
                    </StackRow>
                  </Stack>
                </div>
              </Box> */}
              <NewFileUploadComponent
                setFile={setFile}
                label={"Upload FSSAI License"}
                defaultImg={file || data?.FSSAI_License}
                title={"Upload License"}
                text={"Upload License here"}
                defaultfile={data?.FSSAI_License ? true : false}
                isAcceptFile={(file) => file.type === "application/pdf"}
                warningMessage="Please select Image or PDF for FSSAI License"
              />
            </Grid>
          </Grid>
          <Grid container spacing={"16px"} mt={"20px"}>
            <Grid item xs={4}>
              <CustomSelect
                fieldName={"bankId"}
                fieldValue={"BankName"}
                value={restaurantFormData?.bankId}
                key={restaurantFormData?.bankId}
                placeholder="Select"
                // required
                name="BankName"
                optionList={bankList}
                label="Select Bank Name"
                onChange={(e) => onChange(e, BankName)}
                onBlur={(e) => setIsBankNameTouched(true)}

              // onChange={(e) => setBankName(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                label="IFSC Code"
                value={IFSCCode || data?.bankDetailsId?.IFSCCode}
                // required
                name={"IFSCCode"}
                // hasError={!(IFSCCode?.trim() !== "" && IFSCCode?.length === 7) && IFSCCode}
                hasError={
                  !(
                    IFSCCode?.trim() !== "" &&
                    /^[A-Z]{4}0[A-Z0-9]{6}$/.test(IFSCCode)
                  ) && IFSCCode
                }
                errorMassage={"Please Enter a valid IFSC code"}
                placeholder="Enter IFSC code"
                // onBlur={(e) => setIsIfscTouched(true)}
                onChange={onChange}
                // onChange={(e) => setIfsc(e.target.value)}
                onInput={(e) => {
                  if (e?.target?.value) {
                    e.target.value = e?.target?.value.replace(/[^A-Z0-9]/gi, '').slice(0, 11);
                  }
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomTextField
                label="Bank Account Number"
                // required
                name="accountNumber"
                value={accountNumber || data?.bankDetailsId?.accountNumber}
                placeholder="Enter account number "
                // hasError={!(accountNumber?.match?.('[0-9]{12}') && accountNumber?.length === 12) && isAccNoTouch}
                hasError={
                  !(
                    /^[0-9]{15,16}$/.test(accountNumber)
                  ) && accountNumber.length > 0
                }
                errorMassage={"Please enter a valid account number"}
                // onBlur={(e) => setIsAccNoTouched(true)}
                onChange={onChange}
                onInput={(e) => {
                  if (e?.target?.value) {
                    e.target.value = e?.target?.value.replace(/[^0-9]/gi, '').slice(0, 16);
                  }
                }}
              // onChange={(e) => setAccNo(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            mt={"20px"}
          >
            <Grid item>
              <CustomButton
                // type="submit"
                onClick={() => handleBack()}
                variant="outlined"
                sx={{
                  fontSize: {
                    xs: "0.8rem",
                    sm: "1rem",
                    margin: "0.6rem",
                    cursor: "pointer",
                  },
                }}
                textcontent="Back"
              />
            </Grid>
            <Grid item>
              <CustomButton
                type="submit"
                variant="contained"
                onClick={() => handleSubmit()}
                disabled={!isFormValid()}
                sx={{
                  fontSize: {
                    xs: "0.8rem",
                    sm: "1rem",
                    margin: "0.6rem",
                    cursor: "pointer",
                  },
                }}
                textcontent="Save & Next"
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default OutletInfo;
